import React, {useState, useEffect} from 'react';
import utils from './utils/utils'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import './App.css';

import KPIS from './components/Kpis'
import Reviews from './components/Reviews'

const AWS = require('aws-sdk')


function getUrlVars() {
  var vars = {};
  window.location.href.replace(/[#&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
      vars[key] = value;
  });
  return vars;
}


AWS.config.region = 'eu-west-2'; // Région
AWS.config.credentials = new AWS.CognitoIdentityCredentials({
    IdentityPoolId: 'eu-west-2:13d810d5-435e-453b-8cd4-c3ea362d4d89',
    Logins: {
      'cognito-idp.eu-west-2.amazonaws.com/eu-west-2_Bm4KP9CbL': getUrlVars()['id_token']
    }
});

function App() {
  const [startDate, setStartDate] = useState((new Date()).getTime() - 1000*30*24*60*60);
  const [endDate, setEndDate] = useState((new Date()).getTime());
  const [loggedin, setLoggedin]= useState(false)
  const [brand, setBrand] = useState('')
  const [data, setData] = useState({})
  const [brands, setBrands] = useState([])
    
  const filter = {
    'startDate': startDate/1000,
    'endDate': endDate/1000,
    'brand': brand,
    'sort': 'timestamp'
  }

  useEffect(() => {
    async function checkLoggedIn () {
      return new Promise((res) => {
        AWS.config.credentials.refresh((error) => {
          if (error) {
            console.warn(error)
            window.location.replace("https://zevoice-dashboard-beta.auth.eu-west-2.amazoncognito.com/login?client_id=193pbgh938lhpgjf5efeldifc5&response_type=token&scope=aws.cognito.signin.user.admin+email+openid+phone+profile&redirect_uri=" + window.location.origin);
            return error
          }
          setLoggedin(true)
          res()
        })
      })
    }

    async function getData() {
      console.log('COUCOU0')
      await utils.fetchData(filter).then((data) => {
        console.log('COUCOU1', data)
        if (data.hasOwnProperty('brands')) {
          console.log('COUCOU2', data)
          setBrands(data.brands)
          setBrand(data.brands[0])
        }
      })
    }

    checkLoggedIn()
    getData()
  }, [])

  useEffect(() => {
    async function getData(setter) {
      await utils.fetchData(filter).then((data) => {
        if (!data.hasOwnProperty('brands')) {
          console.log(data)
          setter(data)
        }
      })
    }
    console.log(filter)
    getData(setData)
  }, [brand, startDate, endDate])
  
  return (
    <div className="App">
      <header className="App-header">
        <h1>Centre de Contrôle</h1>
        {
          brands.length > 1 ? (<div id="brand_picker">
            <select onChange={v => setBrand(v.target.value)}>
              {
                brands.map((v, id) => <option key={id} value={v}>{v}</option>)
              }
            </select>
          </div>) : <></>
        }
        <div id='date_pickers'>
          <DatePicker dateFormat="dd/MM/yyyy" className='date_picker' selected={startDate} onChange={date => setStartDate(date)} />
           - 
          <DatePicker dateFormat="dd/MM/yyyy" className='date_picker' minDate={startDate} selected={endDate} onChange={date => setEndDate(date)} />
        </div>
        <img className="loading_gif" src="loading.gif"/>
      </header>
      <div id="Modules">
        <p>Indicateurs clés de performance (KPI)</p>
        <KPIS kpiData={data['kpis']}/>
        <p>Avis</p>
        <Reviews reviews={data['feedbacks']} brand={brand}/>
      </div>
    </div>
  );
}
/*
*/
export default App;

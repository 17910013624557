class Utils {

    getUrlVars() {
        var vars = {};
        var parts = window.location.href.replace(/[#&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
            vars[key] = value;
        });
        return vars;
    }

    fetchData (filters) {
        Array.from(document.getElementsByClassName('loading_gif')).map(
            (x) => x.style.display=''
        )
        return fetch(
            'https://eknv55y1jl.execute-api.eu-west-2.amazonaws.com/Prod/RequestDatabase',
            {
                method: "POST",
                headers: { "Authorization": this.getUrlVars()['access_token'] },
                body: JSON.stringify(filters)
            }
        )
        .then((response) => {    
            Array.from(document.getElementsByClassName('loading_gif')).map(
                (x) => x.style.display='none'
            )
            if (!response.ok) {
                return {}
            }
            return response.json()
        })
        .catch((err) => {
            Array.from(document.getElementsByClassName('loading_gif')).map(
                (x) => x.style.display='none'
            )
            console.warn(err)
            return {}
        });
    }
} 

let utils = new Utils();

console.log(utils)

export default utils;
import React, { useState, useEffect } from "react";
import './Kpis.css'

function KPIS ({kpiData}) {
    
    const kpis_titles = {
        'nb_positive': 'Taux de commentaires positifs',
        'nb_negative': 'Taux de commentaires négatifs',
        'nb_total': 'Nombre total de commentaires sur la période',
        'last_24h': 'Nombre de commentaires des dernières 24h'
    }
    const kpis_modif = {
        'nb_positive': (x) => x + '%',
        'nb_negative': (x) => x + '%',
        'nb_total': (x) => x,
        'nps': (x) => x,
        'last_24h': (x) => '+' + x
    }
    const output = []
    for (let data in kpiData) {
        if (kpis_titles.hasOwnProperty(data)) {
            output.push(
                <div className="card kpi" key={data}>
                    <p className='kpi_title'>{kpis_titles[data]}</p>
                    <p className='kpi_value'>{kpis_modif[data](Math.round(kpiData[data]))}</p>
                </div>
            )
        }
    }
    return(
    <div id='kpi_box'>
        {output}
    </div>
    )
}

/* const KPIS = () => {
    const [kpis, setKpis] = useState([]);

    useEffect(
        () => {
            async function getKpis (setVariable) {
                console.log('TEST1')
                fetchKpis().then((console.log))
                await fetchKpis().then(
                    (response)=>{
                        console.log('HOLA')
                        setVariable([
                            {'title': 'Nombre d\'avis total :', 'value': response[0]['total']},
                            {'title': 'Nombre d\'avis sur les 7 derniers jours :', 'value': response[0]['total_last_7']},
                            {'title': 'Nombre d\'avis sur les 30 derniers jours :', 'value': response[0]['total_last_30']},
                            {'title': 'Nombre d\'avis sur les 24 dernières heures :', 'value': response[0]['total_last_24h']},
                            {'title': 'Nombre d\'avis positifs ou neutres sur les 30 derniers jours :', 'value': response[0]['total_last_30_pos']},
                            {'title': 'Nombre d\'avis négatifs sur les 30 derniers jours :', 'value': response[0]['total_last_30_neg']},
                        ])
                    }
                )
                console.log('TEST2')
            }
            getKpis(setKpis)
        },
        []
    );

    console.log(kpis)

    return <div id="kpi_box">
        {kpis.map(
            (item, id) => <div key={id} className="card kpi">
                <p className="kpi_title">{item.title}</p>
                <p className="kpi_value">{item.value}</p>
            </div>
        )}
    </div>
}*/

export default KPIS;
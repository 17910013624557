import React, { useState, useEffect } from "react";
import './Reviews.css'

function getUrlVars() {
    var vars = {};
    window.location.href.replace(/[#&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

const Reviews = ({reviews, brand}) => {
    const sentiment_colors = {'POSITIVE': 'green', 'NEGATIVE':'red', 'NEUTRAL': '#b4b4b4', 'MIXED': 'orange'}

    const load_audio = (e, id, brand) => {
        const target = e.target;
        fetch(
            'https://vusa9b7co8.execute-api.eu-west-2.amazonaws.com/prod/audio-url?id=' + id.replace(':', '-') + '&brand=' + brand,
            {
                headers: { "Authorization": getUrlVars()['id_token'] },
                method: 'GET'
            }
        ).then(
            response => {
                if (!response.ok) {
                    return ''
                }
                return response.json()
            }
        ).then((url) => {
            console.log(target)
            target.src = url;
        })
    }

    reviews  = reviews ? reviews : []; 

    return <div id="review_box">
        {reviews.filter(
            x => x.text != 'nan'
        ).map(
            (item, id) => <div key={id} className="card review" style={{borderBottomColor:sentiment_colors[item.Predominant_Sentiment_Comprehend]}}>
                <p className="date">{(new Date(item.timestamp)).toLocaleString()}</p>
                <p className="content">{item.text}</p>
                {item.hasOwnProperty('text_original') && item.text_original!='nan' && item.text_original!=item.text ? <><p className="date">Texte original</p><p className="content">{item.text_original}</p></> : ''}
                <audio controls onFocus={(e) => {load_audio(e, item.id, brand)}}></audio>
            </div>
        )}
    </div>
}

export default Reviews;